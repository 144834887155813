import { ReactNode } from "react";
import tw from "twin.macro";
import type { Dayjs } from "dayjs";
import { Button, SyncButton } from "../../../components/rxLibrary/buttons";
import { Text } from "../../../components/rxLibrary/typography";
import { IconPlus } from "../../../components/rxLibrary/icons";
import { Box } from "../../../components/rxLibrary/box";
import { HumanizedTimeAgo } from "../../../components/dates/HumanizedTimeAgo";
import {
  ScrollOverlay,
  useScrollOverlay,
} from "../../../components/ScrollOverlay";

export function ShoppingRxPanel({
  counter,
  syncDate,
  children,
  inventory,
  isLoading,
  onSync,
  openItemsModal,
}: {
  counter: number;
  children: ReactNode;
  isLoading: boolean;
  syncDate?: Dayjs;
  inventory?: boolean;
  onSync: () => void;
  openItemsModal: () => void;
}) {
  const { ref, hasScrollOverlay } = useScrollOverlay();

  const title = inventory ? "Inventory to Buy" : "Prescriptions to Buy";
  const isEmpty = counter === 0;
  const imageAlt = inventory ? "Inventory" : "Prescriptions";
  const buttonLabel = inventory ? "Add NDC / Item" : "Add Rx";
  const imageSrc = inventory
    ? "/assets/shopping/inventory-icon.png"
    : "/assets/shopping/prescription-icon.png";

  return (
    <Box
      tw="flex-1 rounded-lg border overflow-y-hidden flex flex-col"
      bgColor="white"
      borderColor="grey-2"
    >
      <div tw="pt-[5px] px-[5px]">
        <div
          tw="flex justify-between items-center rounded-t-md p-[14px]"
          css={inventory ? tw`bg-[#ffe01c]` : tw`bg-[#d0e9ff]`}
        >
          <div tw="flex space-x-[12px]">
            <img src={imageSrc} alt={imageAlt} tw="w-[48px]" />

            <div>
              <Text tw="ml-[3px]" size={23} block>
                {title} {!isEmpty && `(${counter})`}
              </Text>

              <div tw="flex items-center">
                <SyncButton
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={onSync}
                />

                {!isLoading && !!syncDate && (
                  <Text color="grey-1">
                    Last Synced with PMS -{" "}
                    <HumanizedTimeAgo date={syncDate} capitalize />
                  </Text>
                )}
              </div>
            </div>
          </div>

          <Button
            variant="secondary"
            icon={<IconPlus />}
            onClick={openItemsModal}
          >
            {buttonLabel}
          </Button>
        </div>

        <div tw="flex relative shadow-[0_4px_3px_0_#00000028] content-stretch h-[38px] py-[8px]">
          <Box tw="flex-1 border-r px-[8px]" borderColor="grey-2">
            {!isEmpty && (
              <Text weight="bold">
                {inventory ? "Inventory Item" : "Rx Details"}
              </Text>
            )}
          </Box>
          <Box
            tw="border-r px-[8px]"
            css={inventory ? tw`w-[137px]` : tw`w-[160px]`}
            borderColor="grey-2"
          >
            {!isEmpty && <Text weight="bold">Prescriber + Quantity</Text>}
          </Box>
          <div tw="w-[119px] px-[8px]">
            {!isEmpty && <Text weight="bold">Substitutions</Text>}
          </div>
        </div>
      </div>

      <div
        tw="h-full overflow-y-auto flex-1 py-[13px] px-[10px] space-y-[10px]"
        ref={ref}
      >
        {isEmpty ? (
          <div tw="flex justify-center py-[39px]">
            <Text size="medium" color="grey-1" center>
              There are no prescriptions is your list.
              <br />
              Use the ‘ + Add Rx’ button above to build your list
            </Text>
          </div>
        ) : (
          children
        )}
      </div>

      {hasScrollOverlay && <ScrollOverlay />}
    </Box>
  );
}
