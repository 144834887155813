import React, { ReactNode, useMemo } from "react";
import type {
  ItemInModal,
  ItemInCart,
} from "../../../../../../utilities/types";
import { Box } from "../../../../../../components/rxLibrary/box";
import { Switch } from "../../../../../../components/rxLibrary/switch";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { formatNdc } from "../../../../../../utilities/ndc/formatNdc";
import { formatDrugNameWithDetails } from "../../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { formatDrugDispenseQuantity } from "../../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { ShoppingRxItemPanelEohBoh } from "./ShoppingRxItemPanelEohBoh";

// TODO: move the PrescriptionTag to a shared location
import { PrescriptionTag } from "../../../../ShoppingList/shoppingListTable/columns/SubstitutionsCol/PrescriptionTag";

export function ShoppingRxItemPanel({
  item,
  children,
  inventory,
  itemNotePopover,
  updateItemPackSize,
  updateItemManufacturer,
}: {
  item: ItemInCart | ItemInModal;
  children: ReactNode;
  inventory?: boolean;
  itemNotePopover?: false | JSX.Element;
  updateItemPackSize: (value: boolean) => void;
  updateItemManufacturer: (value: boolean) => void;
}) {
  const { drug, packSize: hasPackSize, manufactutrer: hasManufacturer } = item;
  const { ndc, manufacturer } = drug;

  const ndcFormatted = useMemo(() => formatNdc(ndc), [ndc]);
  const drugDetails = useMemo(() => formatDrugNameWithDetails(drug), [drug]);

  const quantityDescription = useMemo(() => {
    return formatDrugDispenseQuantity(drug);
  }, [drug]);

  return (
    <Box
      tw="flex rounded shadow-[0_0_1px_1px_#00000014] py-[10px] h-[87px]"
      bgColor="white"
    >
      <Box tw="border-r flex-1 px-[10px]" borderColor="grey-2">
        <Text weight="bold" block>
          {drugDetails}
        </Text>
        <Text block>
          {quantityDescription} | {manufacturer}
        </Text>
        <Text block>
          NDC: {ndcFormatted}
          {!!inventory && <ShoppingRxItemPanelEohBoh item={item} />}
        </Text>
      </Box>

      {children}

      <Box tw="space-y-1 w-[112px] px-[10px]">
        <div tw="flex items-center space-x-1">
          <Switch checked={hasPackSize} onChange={updateItemPackSize} />
          <Text>Pack size</Text>
        </div>

        <div tw="flex items-center space-x-1">
          <Switch checked={hasManufacturer} onChange={updateItemManufacturer} />
          <Text>Manufacturer</Text>
        </div>

        <div tw="flex items-center justify-between">
          <div>
            <PrescriptionTag
              isInventory={!!inventory}
              prescription={item}
              small
            />
          </div>

          <div>{itemNotePopover}</div>
        </div>
      </Box>
    </Box>
  );
}
