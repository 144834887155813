import React from "react";
import "twin.macro";
import { FullPageLoader } from "../../loaders/FullPageLoader";
import { WideContainer } from "../../containers/WideContainer";
import { Pagination } from "../../rxLibrary/pagination/Pagination";
import { Text } from "../../rxLibrary/typography";
import { Button } from "../../rxLibrary/buttons";
import { useModal } from "../../rxLibrary/modals";
import { IconGear } from "../../rxLibrary/icons";
import { useRefillOpportunities } from "../RefillOpportunitiesContext";
import { RefillOpportunitiesDownloadCsvButton } from "./RefillOpportunitiesDownloadCsvButton";
import { RefillOpportunitiesReportTable } from "./RefillOpportunitiesReportTable";
import { RefillOpportunitiesFilters } from "./RefillOpportunitiesReportFilters";
import { AcquisitionCostSettings } from "./AcquisitionCost/AcquisitionCost";
import { RefillOpportunitiesPaymentsReportLink } from "./RefillOpportunitiesPaymentsReportLink";

const TitleHeader = Text.withComponent("h1");

export function RefillOpportunitiesReport() {
  const {
    pharmacyId,
    isLoading,
    pagination,
    totalProfit,
    refillMappings,
    updatePagination,
  } = useRefillOpportunities();
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <WideContainer tw="pt-[68px] pb-[200px]">
      <div tw="flex justify-between items-end mb-[17px]">
        <div tw="flex">
          <div tw="flex items-center pl-[16px] pr-[10px]">
            <img
              tw="h-[53px]"
              src="/assets/pages/refill-opportunities/savings-opportunity.png"
            />
          </div>

          <div>
            <TitleHeader size={30} block>
              Refill Opportunities
            </TitleHeader>
            <Text weight="bold" size="large">
              {totalProfit
                ? `${totalProfit} - Available refills (Excludes in-progress refills)`
                : "No available refills"}
            </Text>
          </div>
        </div>

        <div tw="flex items-end space-x-1">
          <RefillOpportunitiesPaymentsReportLink />

          {!!refillMappings?.length && (
            <Button
              variant="text-1"
              icon={<IconGear />}
              disabled={!pharmacyId}
              onClick={openModal}
            >
              Acquisition Cost Setup
            </Button>
          )}

          <RefillOpportunitiesDownloadCsvButton />
        </div>
      </div>

      <RefillOpportunitiesFilters />
      <RefillOpportunitiesReportTable />
      {!!pagination && (
        <Pagination
          pagination={pagination}
          updatePagination={updatePagination}
        />
      )}

      {isLoading && <FullPageLoader />}

      {isModalOpen && <AcquisitionCostSettings close={closeModal} />}
    </WideContainer>
  );
}
