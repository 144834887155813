import { css, styled } from "twin.macro";
import { Text, Title } from "../../../components/rxLibrary/typography";

type SizeVariants = "small" | "large";

const BigNumberBox = styled.div(({ variant }: { variant: SizeVariants }) => [
  css`
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
  `,
  getHeight(variant),
]);

function getHeight(variant: SizeVariants) {
  let height = "200px";
  if (variant === "large") height = "300px";

  return css`
    height: ${height};
  `;
}

function getSize(variant: SizeVariants, value?: string) {
  if (!value) return "small";
  if (variant === "small") return 30;
  return 42;
}

export function BigNumber({
  title,
  subtitle,
  value,
  variant,
}: {
  title: string;
  subtitle?: string;
  value?: string;
  variant: SizeVariants;
}) {
  const textSize = getSize(variant, value);

  return (
    <BigNumberBox variant={variant}>
      <Title block variant="h2" color="black">
        {title}
      </Title>
      <Text size="small" block weight="bold" color="grey-1">
        {subtitle}
      </Text>

      <Text size={textSize} block tw="mt-2">
        {value ?? "No data to display"}
      </Text>
    </BigNumberBox>
  );
}
