import { useMemo } from "react";
import "twin.macro";
import type { ItemInModal, ItemInCart } from "../../../../../utilities/types";
import type { Dayjs } from "dayjs";
import { Box } from "../../../../../components/rxLibrary/box";
import { Text } from "../../../../../components/rxLibrary/typography";
import { ShoppingRxItemPanel } from "./ShoppingRxItemPanel/ShoppingRxItemPanel";
import { ShoppingRxItemHeader } from "./ShoppingRxItemHeader/ShoppingRxItemHeader";
import { formatDispenseQuantity } from "../../../utils/formatDispenseQuantity";
import { formatPrescriberName } from "../../../../../utilities/helpers";

export function ShoppingRxPrescriptionItem({
  item,
  isInCart,
  hasItemNote,
  itemNotePopover,
  removeItem,
  updateItemPackSize,
  openItemNotePopover,
  updateItemPurchaseBy,
  updateItemManufacturer,
}: {
  removeItem: () => void;
  updateItemPackSize: (value: boolean) => void;
  updateItemManufacturer: (value: boolean) => void;
} & (
  | {
      item: ItemInCart;
      isInCart: true;
      hasItemNote: boolean;
      itemNotePopover: false | JSX.Element;
      openItemNotePopover: () => void;
      updateItemPurchaseBy: (value?: Dayjs) => void;
    }
  | {
      item: ItemInModal;
      hasItemNote?: undefined;
      itemNotePopover?: undefined;
      openItemNotePopover?: undefined;
      updateItemPurchaseBy?: undefined;
      isInCart?: false;
    }
)) {
  const { prescriber } = item;

  const headerProps = isInCart
    ? {
        item,
        isInCart,
        hasItemNote,
        openItemNotePopover,
        updateItemPurchaseBy,
      }
    : { item };

  const dispensedQuantity = useMemo(() => {
    return formatDispenseQuantity(item);
  }, [item]);

  const prescriberName = useMemo(() => {
    return formatPrescriberName(prescriber);
  }, [prescriber]);

  return (
    <div tw="rounded-lg p-[5px] min-h-[128px] bg-[#e8f4ff]">
      <ShoppingRxItemHeader {...headerProps} removeItem={removeItem} />

      <ShoppingRxItemPanel
        item={item}
        itemNotePopover={itemNotePopover}
        updateItemPackSize={updateItemPackSize}
        updateItemManufacturer={updateItemManufacturer}
      >
        <Box tw="border-r w-[157px] px-[10px]" borderColor="grey-2">
          <Text weight="bold" block>
            Prescriber: {prescriberName}
          </Text>
          {!!dispensedQuantity && <Text block>{dispensedQuantity}</Text>}
        </Box>
      </ShoppingRxItemPanel>
    </div>
  );
}
