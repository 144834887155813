import { useCallback } from "react";
import "twin.macro";
import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";

const HIDDEN_DATE_INPUT_PICKER_STYLE = {
  height: 0,
  visibility: "hidden",
  width: 0,
  padding: 0,
  border: 0,
  margin: 0,
} as const;

export function ShoppingRxItemHeaderDatePicker({
  date,
  open,
  disableToday,
  onChange,
  onOpenChange,
}: {
  date?: Dayjs;
  open?: boolean;
  disableToday?: boolean;
  onChange: (date?: Dayjs) => void;
  onOpenChange: (open: boolean) => void;
}) {
  const handleChange = useCallback(
    (date: Dayjs) => {
      const newBuyByDate = date.isSame(new Date(), "day") ? undefined : date;
      onChange(newBuyByDate);
    },
    [onChange]
  );

  const disabledDate = useCallback(
    (date: Dayjs) => {
      const now = new Date();
      if (disableToday && date.isSame(now, "day")) return true;
      const isDisabled = date.isBefore(now, "day");
      return isDisabled;
    },
    [disableToday]
  );

  if (!open) return null;
  return (
    <DatePicker
      open={open}
      value={date}
      style={HIDDEN_DATE_INPUT_PICKER_STYLE}
      showNow={!disableToday}
      onChange={handleChange}
      onOpenChange={onOpenChange}
      disabledDate={disabledDate}
    />
  );
}
