import React, { useCallback, useMemo, useState } from "react";
import tw, { styled } from "twin.macro";
import { useHistory } from "react-router-dom";
import { Tooltip } from "antd";
import { OrderHistoryOrder } from "../../../../../utilities/types";
import { DATE_WITH_YEAR } from "../../../../../utilities/dates/dates.constants";
import { stringifyQueryParams } from "../../../../../utilities/queryParams/stringifyQueryParams";
import { formatDateToFormat } from "../../../../../utilities/dates/formatDateToFormat";
import {
  currencyWithCentsAtAllTimes,
  currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits,
} from "../../../../../utilities/numbers/currency";
import { IconBell, IconDown } from "../../../../../components/rxLibrary/icons";
import { Text } from "../../../../../components/rxLibrary/typography";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { GREY_4 } from "../../../../../components/rxLibrary/colors";
import { Col, Row } from "../../../../../components/rxLibrary/grid";
import { OrderHistoryRowSummary } from "./OrderHistoryRowSummary";
import { removeNonAlphanumericChars } from "../../../../../utilities/strings/removeNonAlphanumericChars";
import { SHIP_DATE_WARNING } from "../OrderHistory.constants";

const SummariesWrapper = styled.div`
  background-color: ${GREY_4};
`;

export function OrderHistoryRow({
  order,
  supplierId,
  pharmacyId,
  hasShippingCost,
}: {
  order: OrderHistoryOrder;
  supplierId: number;
  pharmacyId: number;
  hasShippingCost: boolean;
}) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const {
    fileIds,
    fullName,
    orderInfo,
    totalPrice,
    purchasedAt,
    shippingCost,
    itemSummaries,
    purchaseStatus,
    purchaseOrderNumber,
    purchaseDateMissingUsingShipDate,
  } = order;
  const hasItemSummaries = itemSummaries.length > 0;
  const isCancelled = purchaseStatus === "Canceled";

  const invoicesStr = useMemo(() => {
    const invoicesCounter = fileIds.length;
    if (invoicesCounter === 0) return;
    if (invoicesCounter === 1) return "Invoice";
    return `Invoices (${invoicesCounter})`;
  }, [fileIds.length]);

  const hasError = useMemo(() => {
    return itemSummaries.some((itemSummary) => {
      return itemSummary.items.some((item) => item.errorMessage);
    });
  }, [itemSummaries]);

  const total = useMemo(() => {
    return currencyWithCentsAtAllTimes(totalPrice);
  }, [totalPrice]);

  const orderDate = useMemo(() => {
    return formatDateToFormat(purchasedAt, DATE_WITH_YEAR) ?? "";
  }, [purchasedAt]);

  const shippingCostFormatted = useMemo(() => {
    if (!hasShippingCost) return;
    return currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(shippingCost);
  }, [shippingCost, hasShippingCost]);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const goToInvoice = useCallback(() => {
    const queryParams = stringifyQueryParams({
      fileId: fileIds,
      pharmacyId,
      purchaseOrderNumber: removeNonAlphanumericChars(purchaseOrderNumber),
    });
    history.push({
      pathname: `/manufacturer-store/${supplierId}/invoices`,
      search: queryParams,
    });
  }, [history, fileIds, supplierId, pharmacyId, purchaseOrderNumber]);

  return (
    <>
      <Row>
        <Col css={hasShippingCost ? tw`w-[140px]` : tw`w-[180px]`}>
          <Text weight="bold" tw="break-words">{orderInfo}</Text>
        </Col>

        <Col tw="w-[95px]">
          <Text weight="bold">
            {!!orderDate && purchaseDateMissingUsingShipDate ? (
              <Tooltip title={SHIP_DATE_WARNING}>{orderDate}*</Tooltip>
            ) : (
              orderDate
            )}
          </Text>
        </Col>

        <Col tw="flex-1">
          <Text weight="bold">{fullName}</Text>
        </Col>

        <Col tw="w-[115px]">
          <Text weight="bold">{purchaseStatus}</Text>
        </Col>

        {shippingCostFormatted && (
          <Col tw="w-[100px]">
            <Text weight="bold">{shippingCostFormatted}</Text>
          </Col>
        )}

        <Col tw="w-[110px]">
          <Text weight="bold">{total}</Text>
        </Col>

        <Col tw="w-[108px]">
          {invoicesStr ? (
            <Button variant="text-1" onClick={goToInvoice} block fullWidth>
              {invoicesStr}
            </Button>
          ) : (
            <Text block center color="grey-1">
              {isCancelled ? "N/A" : "Invoice Pending"}
            </Text>
          )}
        </Col>

        <Col tw="w-[153px]">
          <div tw="flex items-center">
            {hasItemSummaries && (
              <Button
                variant="text-1"
                icon={isOpen ? <IconDown tw="rotate-180" /> : <IconDown />}
                onClick={toggle}
              >
                Order Details
              </Button>
            )}

            {hasError && <IconBell color="red" />}
          </div>
        </Col>
      </Row>

      {isOpen && (
        <SummariesWrapper tw="px-8 py-6 space-y-2">
          {itemSummaries.map((itemSummary, i) => (
            <OrderHistoryRowSummary key={i} itemSummary={itemSummary} />
          ))}
        </SummariesWrapper>
      )}
    </>
  );
}
