import { ButtonHTMLAttributes, ReactNode, useMemo } from "react";
import tw, { css } from "twin.macro";
import type { SerializedStyles } from "@emotion/react";
import { BLACK, BLUE_1, GREY_1, GREY_2, GREY_3, WHITE } from "../colors";
import { IconRefresh } from "../icons";

export { BackButton } from "./BackButton";
export { EnhancedShowSupplierButton } from "./EnhancedShowSupplierButton";

type IButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "large"
  | "text-1"
  | "text-2";

export function Button({
  variant,
  block,
  fullWidth,
  icon,
  children,
  type = "button",
  ...props
}: {
  icon?: ReactNode;
  variant?: ButtonType;
  block?: boolean;
  fullWidth?: boolean;
} & IButtonProps) {
  const css = useMemo(
    () => [
      BASE_BUTTON_CSS,
      ...getVariantStyle(variant),
      block ? tw`flex` : tw`inline-flex`,
      fullWidth ? tw`w-full` : undefined,
    ],
    [variant, block]
  );

  return (
    <button css={css} type={type} {...props}>
      {!!icon && <span>{icon}</span>}
      <span>{children}</span>
    </button>
  );
}

export function IconButton({ type = "button", ...props }: IButtonProps) {
  return <button css={ICON_BUTTON_CSS} type={type} {...props} />;
}

export function ShoppingListButton(props: Omit<IButtonProps, "icon">) {
  return (
    <Button
      {...props}
      tw="gap-x-[7px] h-[54px]"
      icon={
        <img
          src="/assets/components/icon-shopping-list.svg"
          alt="Shopping List"
        />
      }
    />
  );
}

export function SyncButton({
  loading,
  ...props
}: Omit<IButtonProps, "children" | "variant" | "icon"> & { loading?: boolean }) {
  return (
    <Button
      {...props}
      icon={
        <IconRefresh css={loading && tw`animate-[spin_2s_linear_infinite]`} />
      }
      variant="text-2"
    >
      {loading ? "Synching" : "Sync now"}
    </Button>
  );
}

function getVariantStyle(variant?: ButtonType): SerializedStyles[] {
  switch (variant) {
    case "secondary":
      return BUTTON_SECONDARY_STYLES;
    case "tertiary":
      return BUTTON_TERTIARY_STYLES;
    case "large":
      return BUTTON_LARGE_STYLES;
    case "text-1":
      return BUTTON_TEXT_1_STYLES;
    case "text-2":
      return BUTTON_TEXT_2_STYLES;
    default:
      return BUTTON_PRIMARY_STYLES;
  }
}

const BASE_BUTTON_CSS = css`
  font-family: "Archivo Narrow Variable", sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  column-gap: 3px;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const TRANSITION_SOLID_BUTTON_CSS = css`
  transition: opacity 300ms, color 300ms, text-decoration 300ms,
    box-shadow 300ms, background-color 300ms;
`;

const TRANSITION_TRANSPARENT_BUTTON_CSS = css`
  transition: opacity 300ms, color 300ms, text-decoration 300ms;
`;

export const BUTTON_SHADOW_CSS = css`
  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  }

  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    box-shadow: none;
  }
`;

const ICON_BUTTON_CSS = [
  BASE_BUTTON_CSS,
  TRANSITION_SOLID_BUTTON_CSS,
  css`
    line-height: 1.57;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    color: ${BLACK};

    &:hover {
      background-color: ${GREY_3};
    }

    &:active {
      background-color: ${GREY_2};
    }
  `,
];

const BUTTON_PRIMARY_STYLES = [
  TRANSITION_SOLID_BUTTON_CSS,
  BUTTON_SHADOW_CSS,
  css`
    height: 40px;
    padding: 0 20px;
    border-radius: 50px;
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.29;
    color: ${WHITE};
    background-color: ${BLUE_1};

    &:hover {
      background-color: ${BLUE_1}E6;
    }

    &:disabled {
      background-color: ${BLUE_1};
    }
  `,
];

const BUTTON_SECONDARY_STYLES = [
  TRANSITION_SOLID_BUTTON_CSS,
  BUTTON_SHADOW_CSS,
  css`
    height: 40px;
    padding: 0 20px;
    border-radius: 50px;
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.29;
    color: ${BLUE_1};
    border: solid 1px ${GREY_2};
    background-color: ${WHITE};
  `,
];

const BUTTON_TERTIARY_STYLES = [
  TRANSITION_SOLID_BUTTON_CSS,
  BUTTON_SHADOW_CSS,
  css`
    height: 28px;
    padding: 0 15px;
    border-radius: 30px;
    border: solid 1px ${BLUE_1};
    background-color: ${WHITE};
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.29;
    color: ${BLUE_1};
  `,
];

const BUTTON_LARGE_STYLES = [
  TRANSITION_SOLID_BUTTON_CSS,
  css`
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.29;
    color: ${BLUE_1};
    width: 250px;
    height: 48px;
    border-radius: 30px;
    border: solid 1px ${BLUE_1};
    background-color: ${WHITE};

    &:hover,
    &:active {
      color: ${WHITE};
      background-color: ${BLUE_1};
    }

    &:disabled {
      background-color: ${WHITE};

      &:hover {
        color: ${BLUE_1};
      }
    }
  `,
];

const BUTTON_TEXT_1_STYLES = [
  TRANSITION_TRANSPARENT_BUTTON_CSS,
  css`
    height: 22px;
    padding: 0 4px;
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.57;
    color: ${BLUE_1};
    text-decoration: underline transparent;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      color: ${BLACK};
    }

    &:disabled {
      text-decoration: underline transparent;
    }

    /** Has Icon **/
    &:has(> span:not(:only-child)) {
      &:active {
        text-decoration-thickness: 2px;
        color: ${BLUE_1};
      }
    }
  `,
];

const BUTTON_TEXT_2_STYLES = [
  TRANSITION_TRANSPARENT_BUTTON_CSS,
  css`
    height: 24px;
    padding: 0 4px;
    text-decoration: underline;
    font-size: 12px;
    font-stretch: condensed;
    line-height: 2;
    color: ${GREY_1};
    text-decoration: underline;
    text-decoration-thickness: 1px;

    &:hover {
      color: ${BLACK};
    }

    &:active {
      text-decoration-thickness: 2px;
      color: ${BLACK};
    }

    &:disabled {
      color: ${GREY_1};
    }

    /** Has Icon **/
    &:has(> span:not(:only-child)) {
      line-height: 1.57;
      font-size: 14px;
      color: ${BLACK};

      &:hover {
        text-decoration-thickness: 2px;
      }

      &:disabled {
        text-decoration-thickness: 1px;
      }
    }

    /** Don't has Icon **/
    &:has(> span:only-child) {
      text-transform: uppercase;
      line-height: 2;
    }
  `,
];
