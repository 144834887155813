import "twin.macro";
import IconShoppingCartSvg from "../assets/icon-shopping-cart.svg";
import { Text } from "./rxLibrary/typography";
import { styleSvgIcon } from "./rxLibrary/icons";

const IconShoppingCart = styleSvgIcon(IconShoppingCartSvg);

export function ShoppingCartWithNumber({ value }: { value: number }) {
  return (
    <div>
      <div tw="relative inline-block">
        <IconShoppingCart color="blue-2" />
        <Text tw="absolute top-[25%] left-[50%]" color="white">
          {value}
        </Text>
      </div>
    </div>
  );
}
