import React, { useCallback, useMemo, useState } from "react";
import "twin.macro";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import { Button } from "../../rxLibrary/buttons";
import { Popover, useIsPopoverOpen } from "../../rxLibrary/popover";
import { formatPurchaseByToDayjs } from "../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import { PurchaseByButtonConfirmationPopover } from "./PurchaseByButtonConfirmationPopover";
import { formatBuyByText } from "../../../utilities/dates/purchaseBy/formatBuyByText";

export {
  CalendarFooterWarning,
  CalendarFooterNotInStockToday,
} from "./PurchaseByButton.calendarFooters";

type DisableDateFn = NonNullable<DatePickerProps["disabledDate"]>;

const HIDDEN_DATE_INPUT_PICKER_STYLE = {
  height: 0,
  visibility: "hidden",
  width: 0,
  padding: 0,
  border: 0,
  margin: 0,
} as const;

export function PurchaseByButton({
  purchaseBy,
  calendarFooter,
  disableToday = false,
  changesRequireConfirmation = false,
  onChange,
}: {
  purchaseBy?: string;
  calendarFooter?: Element | JSX.Element;
  disableToday?: boolean;
  changesRequireConfirmation?: boolean;
  onChange: (date?: Date) => void;
}) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [valueToConfirm, setValueToConfirm] = useState<Date | null | undefined>(
    null
  );
  const { isPopoverOpen, closePopover, openPopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  const purchaseByDate = useMemo(() => {
    return formatPurchaseByToDayjs(purchaseBy);
  }, [purchaseBy]);

  const purchaseByStr = useMemo(() => {
    return formatBuyByText(purchaseBy);
  }, [purchaseBy]);

  const openDatePicker = useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);

  const setIsDatePickerOpenProxy = useCallback((open: boolean) => {
    setIsDatePickerOpen(open);
  }, []);

  const handleClosePopover = useCallback(() => {
    closePopover();
    setValueToConfirm(null);
    setIsDatePickerOpen(false);
  }, [closePopover]);

  const handleOpenPopover = useCallback(
    (open: boolean) => {
      if (!open) setValueToConfirm(null);
      handleOpenPopoverChange(open);
    },
    [handleOpenPopoverChange]
  );

  const onDatePickerChange: DatePickerProps["onChange"] = useCallback(
    (value) => {
      const date = value ? dayjs(value) : dayjs();
      const currentPurchaseByDate = purchaseByDate ?? dayjs();
      if (date.isSame(currentPurchaseByDate, "day")) return;

      const isFutureDate = date.isAfter(new Date(), "day");
      const newPurchaseBy = isFutureDate
        ? date.startOf("day").toDate()
        : undefined;

      if (changesRequireConfirmation) {
        setValueToConfirm(newPurchaseBy);
        openPopover();
      } else {
        onChange(newPurchaseBy);
      }
    },
    [purchaseByDate, changesRequireConfirmation, onChange, openPopover]
  );

  const disabledDate = useCallback<DisableDateFn>(
    (date) => {
      const now = new Date();
      if (disableToday && date.isSame(now, "day")) return true;
      return date.isBefore(now, "day");
    },
    [disableToday]
  );

  const renderFooter = useCallback(() => {
    return (
      <div>
        {!disableToday && (
          <Button
            variant="text-1"
            onClick={() => {
              setIsDatePickerOpen(false);
              onDatePickerChange(null, "");
            }}
            fullWidth
          >
            BUY TODAY
          </Button>
        )}
        {calendarFooter}
      </div>
    );
  }, [calendarFooter, disableToday, onDatePickerChange]);

  const renderPopoverModal = useCallback(() => {
    if (valueToConfirm === null) return null;
    return (
      <PurchaseByButtonConfirmationPopover
        date={valueToConfirm}
        onChange={onChange}
        closePopover={handleClosePopover}
        openDatePicker={openDatePicker}
      />
    );
  }, [valueToConfirm, onChange, handleClosePopover, openDatePicker]);

  return (
    <>
      <Popover
        open={isPopoverOpen}
        trigger="contextMenu"
        onOpenChange={handleOpenPopover}
        content={renderPopoverModal}
      >
        <Button variant="text-2" onClick={openDatePicker} fullWidth>
          {purchaseByStr}
        </Button>
      </Popover>

      {isDatePickerOpen && (
        <DatePicker
          open={isDatePickerOpen}
          value={purchaseByDate}
          style={HIDDEN_DATE_INPUT_PICKER_STYLE}
          showNow={false}
          onChange={onDatePickerChange}
          disabledDate={disabledDate}
          onOpenChange={setIsDatePickerOpenProxy}
          renderExtraFooter={renderFooter}
        />
      )}
    </>
  );
}
