import { useContext, useMemo } from "react";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { SimpleRouterLink } from "../../rxLibrary/Link";

export function RefillOpportunitiesPaymentsReportLink() {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { reports } = currentBuyingPharmacy ?? {};

  const hasPaymentsReport = useMemo(() => {
    return !!reports?.some((report) => report.id === 41);
  }, [reports]);

  if (!hasPaymentsReport) return null;
  return (
    <SimpleRouterLink to="/performance?report=41">
      See Payments Earned
    </SimpleRouterLink>
  );
}
