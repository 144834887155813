import React from "react";
import { styled } from "twin.macro";
import { css, Global } from "@emotion/react";
import {
  Input,
  AutoComplete as AutoCompleteAntd,
  AutoCompleteProps as AutoCompleteAntdProps,
} from "antd";
import { GREY_2, WHITE, GREY_1, BLUE_1 } from "../rxLibrary/colors";

type AutoCompleteProps = Omit<AutoCompleteAntdProps, "popupClassName">;

export type AutoCompleteOption = NonNullable<
  AutoCompleteProps["options"]
>[number];

const RX_AUTOCOMPLETE_CLASS_NAME = "rx-autocomplete";

const GLOBAL_CSS = css`
  .${RX_AUTOCOMPLETE_CLASS_NAME} {
    .ant-select-item.ant-select-item-option {
      border-bottom: 1px solid ${GREY_2};
      border-bottom: 1px solid ${GREY_2};
      border-radius: 0;
      margin-right: 9px;
      margin-left: 9px;
    }
  }
`;

export const AutoCompleteInputSearch = styled(Input.Search)`
  &.ant-input-search .ant-input-wrapper {
    .ant-input-affix-wrapper {
      border-start-start-radius: 26px;
      border-end-start-radius: 26px;
      padding-left: 20px;
      box-shadow: none;
      border: 1px solid ${GREY_2};
      border-right: none;
    }

    .ant-btn.ant-input-search-button {
      border-start-end-radius: 26px;
      border-end-end-radius: 26px;
      background-color: ${WHITE};
      border: 1px solid ${GREY_2};
      border-left: none;
      color: ${GREY_1};
    }

    &:hover {
      .ant-input-affix-wrapper,
      .ant-btn.ant-input-search-button {
        border-color: ${BLUE_1};
      }

      .ant-btn.ant-input-search-button {
        color: ${BLUE_1};
      }
    }

    .ant-input::placeholder {
      color: ${GREY_1};
    }

    .ant-input-group-addon {
      inset-inline-start: 0;
    }
  }

  &.ant-input-group-wrapper-disabled .ant-input-wrapper {
    .ant-input-affix-wrapper,
    .ant-btn.ant-input-search-button {
      color: ${GREY_1};
      border-color: ${GREY_2};
    }

    .ant-input-affix-wrapper {
      border-start-start-radius: 26px;
      border-end-start-radius: 26px;
    }

    .ant-input-group-addon {
      border-start-end-radius: 26px;
      border-end-end-radius: 26px;
    }

    .ant-btn.ant-input-search-button {
      background-color: transparent;
    }

    &:hover {
      .ant-input-affix-wrapper,
      .ant-btn.ant-input-search-button {
        color: ${GREY_1};
        border-color: ${GREY_2};
      }
    }
  }
`;

export function AutoComplete(props: AutoCompleteProps) {
  return (
    <>
      <AutoCompleteAntd
        {...props}
        popupClassName={RX_AUTOCOMPLETE_CLASS_NAME}
      />
      <Global styles={GLOBAL_CSS} />
    </>
  );
}
