import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";
import keyBy from "lodash/keyBy";
import isEqual from "lodash/isEqual";
import {
  BlobPayload,
  ItemInCart,
  ItemPurchaseDetails,
  PrescriptionGroupAdjustment,
  PurchaseQuantityMethodEnum,
} from "../utilities/types";
import {
  getBlobVersionId,
  getJSONBlob,
  pushJSONBlob,
} from "../services/prescriptions";
import cloneDeep from "lodash/cloneDeep";
import { useAuth0 } from "./AuthenticationContext";
import BuyingPharmacyContext from "./BuyingPharmacyContext";
import { useServerUpdateNotifications } from "./ServerUpdateNotificationsContext";
import { cleanUpPurchaseByString } from "../utilities/dates/purchaseBy/cleanUpPurchaseByString";
import { formatPurchaseByToString } from "../utilities/dates/purchaseBy/formatPurchaseByToString";
import { isInventoryId } from "../utilities/prescriptions/isInventoryId";
import { findAndRemoveFromArray } from "../utilities/arrays/findAndRemoveFromArray";
import { OptimizedCartResponse_All } from "../services/types";
import {updateOptimizedCartSelections} from "../services/legacy/optimizations";

const MAX_INVENTORY_AGE_HOURS = 48;

export type removeFromCartMutation = {
  name: "removeFromCart";
  params: {
    id: string;
  };
};

export type updateItemPurchaseDetailValueMutation<
  K extends keyof ItemPurchaseDetails
> = {
  name: "updateItemPurchaseDetailValue";
  params: {
    id: string;
    key: K;
    value: ItemPurchaseDetails[K];
  };
  options?: {
    undo?: {
      id: string;
      key: K;
      value: ItemPurchaseDetails[K];
    };
  };
};

export type updateItemPurchaseDetailsMutation = {
  name: "updateItemPurchaseDetails";
  params: {
    items: ItemPurchaseDetails[];
  };
};

export type updateItemPurchaseDetailStatusMutation = {
  name: "updateItemPurchaseDetailStatus";
  params: {
    id: string;
    status: string;
    ndc: string;
    manufactutrer: boolean;
    packSize: boolean;
    currentFillId?: string;
    packQuantity?: number;
    processedAt?: string;
    noManufacturerPreference?: boolean;
    supplierId?: number;
  };
};

export type updateItemPurchaseDetailPackQuantityMutation = {
  name: "updateItemPurchaseDetailPackQuantity";
  params: {
    id: string;
    ndc: string;
    status: string;
    manufactutrer: boolean;
    packSize: boolean;
    packQuantity: number;
    currentFillId?: string;
    noManufacturerPreference?: boolean;
  };
};

export type addInventoryItemMutation = {
  name: "addInventoryItem";
  params: {
    item: ItemInCart;
  };
};

export type updateInventoryItemMutation<K extends keyof ItemInCart> = {
  name: "updateInventoryItem";
  params: {
    key: string;
    field: K;
    value: ItemInCart[K];
  };
};

export type removeFromInventoryMutation = {
  name: "removeFromInventory";
  params: {
    id: string;
  };
};

export type updatePrescriptionGroupPurchaseQuantityMutation = {
  name: "updatePrescriptionGroupPurchaseQuantity";
  params: {
    rxNumbers: string[];
    purchaseQuantityMethod: PurchaseQuantityMethodEnum;
    num?: string;
  };
};

export type updatePrescriptionGroupChosenSubstitutionMutation = {
  name: "updatePrescriptionGroupChosenSubstitution";
  params: {
    rxNumbers: string[];
    allowPackSizeSubstitution: boolean;
    allowManufacturerSubstitution: boolean;
  };
};
export type deletePrescriptionGroupAdjustmentsMutation = {
  name: "deletePrescriptionGroupAdjustments";
  params: {
    adjustments: PrescriptionGroupAdjustment[];
  };
  options?: {
    undo?: {
      adjustments: PrescriptionGroupAdjustment[];
    };
  };
};

// THIS ONE IS NOT PUBLICLY EXPOSED BECAUSE IT DOESN'T CURRENTLY NEED TO BE
// IT'S ONLY USED AS AN UNDO
type addPrescriptionGroupAdjustmentsMutation = {
  name: "addPrescriptionGroupAdjustments";
  params: {
    adjustments: PrescriptionGroupAdjustment[];
  };
};

export type ShoppingCartMutation =
  | removeFromCartMutation
  | updateItemPurchaseDetailValueMutation<keyof ItemPurchaseDetails>
  | updateItemPurchaseDetailsMutation
  | updateItemPurchaseDetailStatusMutation
  | updateItemPurchaseDetailPackQuantityMutation
  | addInventoryItemMutation
  | updateInventoryItemMutation<keyof ItemInCart>
  | removeFromInventoryMutation
  | updatePrescriptionGroupPurchaseQuantityMutation
  | updatePrescriptionGroupChosenSubstitutionMutation
  | deletePrescriptionGroupAdjustmentsMutation
  | addPrescriptionGroupAdjustmentsMutation;

type ShoppingCartMutationBatch = {
  id: number;
  mutations: ShoppingCartMutation[];
  status: "created" | "pending" | "submitting" | "submitted";
  submitTime: number;
  clientBlobVersion: number;
};


export function buildBlobPayloadComponents(
  itemPurchaseDetails: ItemPurchaseDetails[],
  inventoryItemsInCart: ItemInCart[],
  prescriptionGroupAdjustments: PrescriptionGroupAdjustment[]
): {
  cartData: ItemPurchaseDetails[];
  invData: ItemInCart[];
  adjData: PrescriptionGroupAdjustment[];
} {
  const blobComponents: {
    cartData: ItemPurchaseDetails[];
    invData: ItemInCart[];
    adjData: PrescriptionGroupAdjustment[];
  } = {
    cartData: [],
    invData: [],
    adjData: [],
  };

  itemPurchaseDetails.forEach((item: ItemPurchaseDetails) => {
    const newItem = { ...item };
    if (newItem.status !== "add" || newItem.note || newItem.purchaseBy) {
      blobComponents.cartData.push(newItem);
    }
  });

  inventoryItemsInCart.forEach((itemList: ItemInCart) => {
    const cItem: ItemPurchaseDetails[] = blobComponents.cartData.filter(
      (obj) => obj.id === itemList.key
    );

    const newItemList: ItemInCart = {
      ...itemList,
    };
    if (cItem && cItem[0]) {
      newItemList.status = cItem[0].status;
      newItemList.packQuantity = cItem[0].packQuantity;
      newItemList.manufactutrer = cItem[0].manufactutrer;
      newItemList.packSize = cItem[0].packSize;
      newItemList.processedAt = cItem[0].processedAt;
      newItemList.supplierId = cItem[0].supplierId;
      newItemList.note = cItem[0].note;
    }

    blobComponents.invData.push(newItemList);
  });

  prescriptionGroupAdjustments.forEach((item: PrescriptionGroupAdjustment) => {
    const newItem = { ...item };
    blobComponents.adjData.push(newItem);
  });
  return blobComponents;
}

function formatItem<T extends ItemInCart | ItemPurchaseDetails>(item: T): T {
  const { purchaseBy, status } = item;

  // Note: Migration for the deprecated "later" state, this could be removed in the future
  if (status === "later") {
    let newPurchaseBy = purchaseBy && cleanUpPurchaseByString(purchaseBy);
    if (!newPurchaseBy) {
      const date = dayjs().add(1, "month").endOf("month");
      newPurchaseBy = formatPurchaseByToString(date);
    }

    return { ...item, status: "list", purchaseBy: newPurchaseBy };
  }

  const newPurchaseBy = cleanUpPurchaseByString(purchaseBy);
  return { ...item, purchaseBy: newPurchaseBy };
}

function formatInventoryItems(inventoryItems: ItemInCart[]): ItemInCart[] {
  const newInventoryItems = inventoryItems
    .filter(({ processedAt }) => {
      if (!processedAt) return true;

      const processedAtDate = dayjs(processedAt);
      const ageHours = dayjs().diff(processedAtDate, "hours", true);
      const keepItem = ageHours < MAX_INVENTORY_AGE_HOURS;
      return keepItem;
    })
    .map(formatItem);

  return newInventoryItems;
}

function formatPmsItems(
  inventoryItems: ItemInCart[],
  cartItems: ItemPurchaseDetails[]
): ItemPurchaseDetails[] {
  const inventoryItemsById = keyBy(inventoryItems, "key");
  const pmsItems = cartItems
    .filter(({ id }) => {
      if (id.indexOf("_") >= 0) {
        // this is leftover from page 3
        return false;
      } else if (isInventoryId(id)) {
        const isInventory = !!inventoryItemsById[id];
        return isInventory;
      } else {
        // If id indicates prescription item, keep it
        return true;
      }
    })
    .map(formatItem);

  return pmsItems;
}

type ShoppingCartServerStateContextType = {
  itemPurchaseDetails: ItemPurchaseDetails[];
  inventoryItemsInCart: ItemInCart[];
  prescriptionGroupAdjustments: PrescriptionGroupAdjustment[];
  shoppingCartLoaded: boolean;
};

type ShoppingCartServerUpdaterContextType = {
  loadCartData: () => Promise<{
    inventory: ItemInCart[];
    cart: ItemPurchaseDetails[];
  } | null>;
  submitShoppingCartMutation: (mutation: ShoppingCartMutation) => void;
  submitOptimizationUpdate: (data: OptimizedCartResponse_All) => Promise<void>;
  reverseUndoableMutations: () => void;
  clearMutationUndoStack: () => void;
  pushBlob: (
    config:
      | { second: boolean; force: false }
      | { force: true; second?: boolean; cb?: () => void }
  ) => void; // non-mutation
  setUseBlob: (state: boolean) => void; // non-mutation
};

const ShoppingCartServerStateContext = createContext<
  ShoppingCartServerStateContextType | undefined
>(undefined);
const ShoppingCartServerUpdaterContext = createContext<
  ShoppingCartServerUpdaterContextType | undefined
>(undefined);
if (process.env.REACT_APP_USE_CONTEXT_DEVTOOL) {
  ShoppingCartServerStateContext.displayName = "ShoppingCartServerStateContext";
  ShoppingCartServerUpdaterContext.displayName =
    "ShoppingCartServerUpdaterContext";
}

export type ServerState = {
  loaded: boolean;
  itemPurchaseDetails: ItemPurchaseDetails[];
  inventoryItemsInCart: ItemInCart[];
  prescriptionGroupAdjustments: PrescriptionGroupAdjustment[];
};
type PushBlobOp =
  | { second: boolean; force: false }
  | { force: true; second?: boolean; cb?: () => void };

export const ShoppingCartServerContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);
  const { activeCartUpdatedEvent } = useServerUpdateNotifications();
  const [cartUpdateExists, setCartUpdateExists] = useState(false);

  const [serverState, setServerState] = useState<ServerState>({
    loaded: false,
    itemPurchaseDetails: [],
    inventoryItemsInCart: [],
    prescriptionGroupAdjustments: [],
  });

  const [shoppingCartMutationSubmissions, setShoppingCartMutationSubmissions] =
    useState<ShoppingCartMutation[]>([]);
  const [mutationBatchId, setMutationBatchId] = useState(0);
  const [shoppingCartMutations, setShoppingCartMutations] = useState<
    ShoppingCartMutationBatch[]
  >([]);
  const [shoppingCartMutationUndos, setShoppingCartMutationUndos] = useState<
    ShoppingCartMutation[]
  >([]);
  const [useBlob, setUseBlob] = useState(false);
  const [pushBlobOp, setPushBlobOp] = useState<PushBlobOp | null>(null);
  const [serverConflictCart, setServerConflictCart] = useState<{
    cart: ItemPurchaseDetails[];
    inventory: ItemInCart[];
    prescriptionGroupAdjustments: PrescriptionGroupAdjustment[];
    op: PushBlobOp;
  } | null>(null);

  useEffect(() => {
    if (activeCartUpdatedEvent > 0) {
      setCartUpdateExists(true);
    }
  }, [activeCartUpdatedEvent, setCartUpdateExists]);

  useEffect(() => {
    setServerState({
      loaded: false,
      itemPurchaseDetails: [],
      inventoryItemsInCart: [],
      prescriptionGroupAdjustments: [],
    });
  }, [currentBuyingPharmacyId]);

  const loadCartData = useCallback(async () => {
    // New Inv comes from the blob
    if (!currentBuyingPharmacyId) return null;

    const token = await getAccessTokenSilently();
    const { data } = (await getJSONBlob(
      currentBuyingPharmacyId,
      token
    )) as BlobPayload;

    if (data?.inventory) {
      const inventoryItems = formatInventoryItems(data.inventory);
      const pmsItems = formatPmsItems(data.inventory, data.cart || []);
      const prescriptionGroupAdjustments: PrescriptionGroupAdjustment[] =
        data.prescriptionGroupAdjustments || [];

      setServerState({
        loaded: true,
        itemPurchaseDetails: pmsItems,
        inventoryItemsInCart: inventoryItems,
        prescriptionGroupAdjustments: prescriptionGroupAdjustments,
      });

      return {
        cart: pmsItems,
        inventory: inventoryItems,
        prescriptionGroupAdjustments: prescriptionGroupAdjustments,
      };
    } else {
      setServerState(() => {
        return {
          loaded: true,
          itemPurchaseDetails: [],
          inventoryItemsInCart: [],
          prescriptionGroupAdjustments: [],
        };
      });
      return { inventory: [], cart: [], prescriptionGroupAdjustments: [] };
    }
  }, [currentBuyingPharmacyId, setServerState, getAccessTokenSilently]);

  const addToOrUpdateCart = useCallback(
    <K extends keyof ItemPurchaseDetails>(
      item: ItemPurchaseDetails,
      updateObjId?: string,
      updateField?: K,
      updateVal?: ItemPurchaseDetails[K]
    ) => {
      const id = item.id;

      setServerState((prevState) => {
        const items = cloneDeep(prevState.itemPurchaseDetails);
        if (updateObjId && updateField && updateVal !== undefined) {
          let found = false;
          items.forEach((obj: ItemPurchaseDetails) => {
            if (obj.id === updateObjId) {
              found = true;
              obj[updateField] = updateVal;

              if (updateField === "status") {
                obj.processedAt =
                  updateVal === "processed"
                    ? new Date().toLocaleString("en-US")
                    : "";
              }
            }
          });
          if (found) {
            return { ...prevState, itemPurchaseDetails: items };
          }
        }

        const currentItems = items.filter((item) => item.id !== id);
        currentItems.push(item);
        return { ...prevState, itemPurchaseDetails: currentItems };
      });
    },
    [setServerState]
  );

  const removeFromCart = useCallback(
    (id: string) => {
      setServerState((prevState) => {
        const items = [...prevState.itemPurchaseDetails];
        const currentItems = items.filter((item) => item.id !== id);

        return { ...prevState, itemPurchaseDetails: currentItems };
      });
    },
    [setServerState]
  );
  const removeFromInventory = useCallback(
    (id: string) => {
      setServerState((prevState) => {
        const items = [...prevState.inventoryItemsInCart].filter(
          (item: ItemInCart) => item.key !== id
        );

        return { ...prevState, inventoryItemsInCart: items };
      });

      removeFromCart(id);
    },
    [setServerState, removeFromCart]
  );

  const updateItemPurchaseDetailStatus = useCallback(
    (
      id: string,
      status: string,
      ndc: string,
      manufactutrer: boolean,
      packSize: boolean,
      currentFillId?: string,
      packQuantity?: number,
      processedAt?: string,
      noManufacturerPreference?: boolean,
      supplierId?: number
    ) => {
      const obj = {
        id: id,
        status: status,
        manufactutrer: manufactutrer,
        packSize: packSize,
        ndc: ndc,
        currentFillId: currentFillId,
        packQuantity: packQuantity,
        processedAt: processedAt,
        noManufacturerPreference: noManufacturerPreference,
        supplierId: supplierId,
        active: true,
      };
      addToOrUpdateCart(obj, id, "status", status);
    },
    [addToOrUpdateCart]
  );
  const updateItemPurchaseDetailValue = useCallback(
    <K extends keyof ItemPurchaseDetails>(
      id: string,
      key: K,
      value: ItemPurchaseDetails[K]
    ) => {
      setServerState((prev) => {
        const oldCartList = prev.itemPurchaseDetails;
        const newCartList = cloneDeep(oldCartList).map((item) => {
          // #5406: we keep pack qty changes from
          let newItem = item;
          if (newItem.id === id) {
            newItem = { ...newItem, [key]: value };

            if (key === "status") {
              newItem.processedAt =
                value === "processed" ? new Date().toLocaleString("en-US") : "";
            }
          }

          return newItem;
        });

        return { ...prev, itemPurchaseDetails: newCartList };
      });
    },
    []
  );

  const updateItemPurchaseDetailPackQuantity = useCallback(
    (
      id: string,
      ndc: string,
      status: string,
      manufactutrer: boolean,
      packSize: boolean,
      packQuantity: number,
      currentFillId?: string,
      noManufacturerPreference?: boolean
    ) => {
      const obj = {
        id: id,
        status: status,
        ndc: ndc,
        manufactutrer: manufactutrer,
        packSize: packSize,
        packQuantity: packQuantity || 1, // _added_ items have min qty of 1
        currentFillId: currentFillId,
        active: true,
        noManufacturerPreference,
      };
      addToOrUpdateCart(obj);
    },
    [addToOrUpdateCart]
  );

  const addInventoryItem = useCallback((item: ItemInCart) => {
    setServerState((prev) => {
      const newItems = [...prev.inventoryItemsInCart, item];
      return { ...prev, inventoryItemsInCart: newItems };
    });
  }, []);

  const updateInventoryItem = useCallback(
    <K extends keyof ItemInCart>(
      key: string,
      field: K,
      value: ItemInCart[K]
    ) => {
      setServerState((prevState) => {
        const newItems = prevState.inventoryItemsInCart.map((item) => {
          return item.key === key ? { ...item, [field]: value } : item;
        });
        return { ...prevState, inventoryItemsInCart: newItems };
      });
    },
    []
  );

  const updatePrescriptionGroupPurchaseQuantity = useCallback(
    (
      rxNumbers: string[],
      purchaseQuantityMethod: PurchaseQuantityMethodEnum,
      num?: string
    ) => {
      setServerState((prevState) => {
        let [prescriptionGroupAdjustments, adjustment] = findAndRemoveFromArray(
          prevState.prescriptionGroupAdjustments,
          (item) => isEqual(item.rxNumbers, rxNumbers)
        );

        const useQuantityInput =
          purchaseQuantityMethod === PurchaseQuantityMethodEnum.Manual;
        adjustment = { ...adjustment, rxNumbers, useQuantityInput };
        if (num !== undefined) {
          if (useQuantityInput) {
            adjustment.quantityToBuy = parseInt(num, 10);
          } else {
            adjustment.numPackages = parseInt(num, 10);
          }
        }

        prescriptionGroupAdjustments = [
          ...prescriptionGroupAdjustments,
          adjustment,
        ];

        return { ...prevState, prescriptionGroupAdjustments };
      });
    },
    []
  );

  const updatePrescriptionGroupChosenSubstitution = useCallback(
    (
      rxNumbers: string[],
      allowPackSizeSubstitution: boolean,
      allowManufacturerSubstitution: boolean
    ) => {
      setServerState((prevState) => {
        const prescriptionGroupAdjustments = [
          ...prevState.prescriptionGroupAdjustments,
        ];
        let adjustment = prescriptionGroupAdjustments.find(
          (adjustment) =>
            adjustment.rxNumbers.join(", ") === rxNumbers.join(",")
        );
        if (!adjustment) {
          adjustment = { rxNumbers };
          prescriptionGroupAdjustments.push(adjustment);
        }
        adjustment.allowManufacturerSubstitution =
          allowManufacturerSubstitution;
        adjustment.allowPackSizeSubstitution = allowPackSizeSubstitution;
        return { ...prevState, prescriptionGroupAdjustments };
      });
    },
    []
  );

  const deletePrescriptionGroupAdjustments = useCallback(
    (adjustmentsToDelete: PrescriptionGroupAdjustment[]) => {
      setServerState((prevState) => {
        let prescriptionGroupAdjustments = [
          ...prevState.prescriptionGroupAdjustments,
        ];
        adjustmentsToDelete.forEach((adjustmentToDelete) => {
          prescriptionGroupAdjustments = prescriptionGroupAdjustments.filter(
            (adjustment) =>
              adjustment.rxNumbers.join(",") !==
              adjustmentToDelete.rxNumbers.join(",")
          );
        });
        return { ...prevState, prescriptionGroupAdjustments };
      });
    },
    []
  );

  const addPrescriptionGroupAdjustments = useCallback(
    (adjustmentsToAdd: PrescriptionGroupAdjustment[]) => {
      setServerState((prevState) => {
        const prescriptionGroupAdjustments = [
          ...prevState.prescriptionGroupAdjustments,
        ];
        adjustmentsToAdd.forEach((adjustmentToAdd) => {
          prescriptionGroupAdjustments.push(adjustmentToAdd);
        });
        return { ...prevState, prescriptionGroupAdjustments };
      });
    },
    []
  );

  const applyMutation = useCallback(
    (mutation: ShoppingCartMutation) => {
      if (mutation.name === "removeFromCart") {
        removeFromCart(mutation.params.id);
      } else if (mutation.name === "updateItemPurchaseDetailValue") {
        updateItemPurchaseDetailValue(
          mutation.params.id,
          mutation.params.key,
          mutation.params.value
        );
      } else if (mutation.name === "updateItemPurchaseDetailStatus") {
        updateItemPurchaseDetailStatus(
          mutation.params.id,
          mutation.params.status,
          mutation.params.ndc,
          mutation.params.manufactutrer,
          mutation.params.packSize,
          mutation.params.currentFillId,
          mutation.params.packQuantity,
          mutation.params.processedAt,
          mutation.params.noManufacturerPreference,
          mutation.params.supplierId
        );
      } else if (mutation.name === "updateItemPurchaseDetailPackQuantity") {
        updateItemPurchaseDetailPackQuantity(
          mutation.params.id,
          mutation.params.ndc,
          mutation.params.status,
          mutation.params.manufactutrer,
          mutation.params.packSize,
          mutation.params.packQuantity,
          mutation.params.currentFillId,
          mutation.params.noManufacturerPreference
        );
      } else if (mutation.name === "addInventoryItem") {
        addInventoryItem(mutation.params.item);
      } else if (mutation.name === "updateInventoryItem") {
        updateInventoryItem(
          mutation.params.key,
          mutation.params.field,
          mutation.params.value
        );
      } else if (mutation.name === "removeFromInventory") {
        removeFromInventory(mutation.params.id);
      } else if (mutation.name === "updatePrescriptionGroupPurchaseQuantity") {
        updatePrescriptionGroupPurchaseQuantity(
          mutation.params.rxNumbers,
          mutation.params.purchaseQuantityMethod,
          mutation.params.num,
        );
      } else if (
        mutation.name === "updatePrescriptionGroupChosenSubstitution"
      ) {
        updatePrescriptionGroupChosenSubstitution(
          mutation.params.rxNumbers,
          mutation.params.allowPackSizeSubstitution,
          mutation.params.allowManufacturerSubstitution
        );
      } else if (mutation.name === "deletePrescriptionGroupAdjustments") {
        deletePrescriptionGroupAdjustments(mutation.params.adjustments);
      } else if (mutation.name === "addPrescriptionGroupAdjustments") {
        addPrescriptionGroupAdjustments(mutation.params.adjustments);
      }
    },
    [
      removeFromCart,
      updateItemPurchaseDetailValue,
      updateItemPurchaseDetailStatus,
      updateItemPurchaseDetailPackQuantity,
      addInventoryItem,
      updateInventoryItem,
      removeFromInventory,
    ]
  );

  const submitShoppingCartMutation = useCallback(
    (mutation: ShoppingCartMutation) => {
      console.log("submitShoppingCartMutation", mutation);
      setShoppingCartMutationSubmissions((prev) => {
        return [...prev, mutation];
      });
    },
    []
  );

  useEffect(() => {
    if (!currentBuyingPharmacyId) {
      return;
    }
    if (shoppingCartMutationSubmissions.length === 0) {
      return;
    }

    const newShoppingCartMutations = [...shoppingCartMutations];
    const mutationBatch = newShoppingCartMutations.find(
      (mutationBatch) => mutationBatch.status === "created"
    );
    if (mutationBatch) {
      mutationBatch.mutations.push(...shoppingCartMutationSubmissions);
    } else {
      newShoppingCartMutations.push({
        id: mutationBatchId,
        mutations: [...shoppingCartMutationSubmissions],
        status: "created",
        submitTime: Date.now(),
        clientBlobVersion: getBlobVersionId(currentBuyingPharmacyId),
      });
      setMutationBatchId(mutationBatchId + 1);
    }
    console.log(
      "submitShoppingCartMutation mutations now",
      newShoppingCartMutations
    );
    setShoppingCartMutations(newShoppingCartMutations);
    setShoppingCartMutationSubmissions((prev) =>
      prev.filter((x) => !shoppingCartMutationSubmissions.includes(x))
    );
  }, [
    currentBuyingPharmacyId,
    shoppingCartMutations,
    shoppingCartMutationSubmissions,
    mutationBatchId,
    setShoppingCartMutations,
    setShoppingCartMutationSubmissions,
    setMutationBatchId,
  ]);

  const markMutationBatchStatus = useCallback(
    (
      status: ShoppingCartMutationBatch["status"],
      mutationBatches: ShoppingCartMutationBatch[]
    ) => {
      setShoppingCartMutations((prev) => {
        return prev.map((mutationBatch) => {
          if (
            mutationBatches.find(
              (mutationBatchToMark) =>
                mutationBatchToMark.id === mutationBatch.id
            )
          ) {
            return {
              ...mutationBatch,
              status: status,
            };
          } else {
            return mutationBatch;
          }
        });
      });
    },
    []
  );

  const createdShoppingCartMutations = useMemo(() => {
    return shoppingCartMutations.filter(
      (mutationBatch) => mutationBatch.status === "created"
    );
  }, [shoppingCartMutations]);

  useEffect(() => {
    if (createdShoppingCartMutations.length > 0) {
      createdShoppingCartMutations.forEach((batch) => {
        const mutations = batch.mutations;
        mutations.forEach((mutation) => {
          console.log("applying mutation", mutation);
          applyMutation(mutation);
          if (
            (mutation.name === "updateItemPurchaseDetailValue" ||
              mutation.name === "deletePrescriptionGroupAdjustments") &&
            mutation.options?.undo
          ) {
            setShoppingCartMutationUndos((prev) => [...prev, mutation]);
          }
        });
      });
      markMutationBatchStatus("pending", createdShoppingCartMutations);
    }
  }, [
    createdShoppingCartMutations,
    applyMutation,
    markMutationBatchStatus,
    setShoppingCartMutationUndos,
  ]);

  const pendingShoppingCartMutations = useMemo(() => {
    return shoppingCartMutations.filter(
      (mutationBatch) => mutationBatch.status === "pending"
    );
  }, [shoppingCartMutations]);

  const submittingShoppingCartMutations = useMemo(() => {
    return shoppingCartMutations.filter(
      (mutationBatch) => mutationBatch.status === "submitting"
    );
  }, [shoppingCartMutations]);

  const reverseUndoableMutations = useCallback(() => {
    const stack = [...shoppingCartMutationUndos];
    stack.reverse();
    stack.forEach((mutation) => {
      if (
        mutation.name === "updateItemPurchaseDetailValue" &&
        mutation.options?.undo
      ) {
        submitShoppingCartMutation({
          name: "updateItemPurchaseDetailValue",
          params: {
            id: mutation.options.undo.id,
            key: mutation.options.undo.key,
            value: mutation.options.undo.value,
          },
        });
      } else if (
        mutation.name === "deletePrescriptionGroupAdjustments" &&
        mutation.options?.undo
      ) {
        submitShoppingCartMutation({
          name: "addPrescriptionGroupAdjustments",
          params: {
            adjustments: mutation.options.undo.adjustments,
          },
        });
      }
    });
    setShoppingCartMutationUndos((prev) => {
      return prev.filter((mutation) => !stack.includes(mutation));
    });
  }, [
    shoppingCartMutationUndos,
    submitShoppingCartMutation,
    setShoppingCartMutationUndos,
  ]);

  const clearMutationUndoStack = useCallback(() => {
    setShoppingCartMutationUndos([]);
  }, []);

  const pushBlob = useCallback(
    (
      config:
        | { second: boolean; force: false }
        | { force: true; second?: boolean; cb?: () => void }
    ) => {
      setPushBlobOp(config);
    },
    [setPushBlobOp]
  );

  const [optimizationUpdates, setOptimizationUpdates] = useState<{optimization: OptimizedCartResponse_All, editVersion: number}[]>([]);
  const submitOptimizationUpdate = useCallback(async (optimization: OptimizedCartResponse_All) => {
    if (optimization.data) {
      const editVersion = optimization.data.editVersion;
      setOptimizationUpdates((prev) => [...prev, {
        optimization: optimization,
        editVersion: editVersion,
        executed: false
      }]);
    }
  }, [setOptimizationUpdates]);

  useEffect(() => {
    (async() => {
      if (optimizationUpdates.length > 0) {
        const update = optimizationUpdates.reduce((acc, cur) => {
          if (cur.editVersion > acc.editVersion) {
            return cur;
          } else {
            return acc;
          }
        }, optimizationUpdates[0]);
        const reviewedOptimizationUpdates = [...optimizationUpdates];
        const optimization = update.optimization;
        if (optimization.data) {
          const accessToken = await getAccessTokenSilently();
          updateOptimizedCartSelections(optimization.data.id, accessToken, optimization.data.selections);
        }
        setOptimizationUpdates((prev) => {
          return prev.filter((update) => !reviewedOptimizationUpdates.map((u) => u.editVersion).includes(update.editVersion));
        })
      }
    })();
  }, [optimizationUpdates, setOptimizationUpdates, getAccessTokenSilently]);

  useEffect(() => {
    if (
      shoppingCartMutations.find(
        (mutationBatch) => mutationBatch.status === "submitted"
      )
    ) {
      setShoppingCartMutations((prev) => {
        return prev.filter(
          (mutationBatch) => mutationBatch.status !== "submitted"
        );
      });
    }
  }, [shoppingCartMutations]);

  useEffect(() => {
    (async () => {
      if (!pushBlobOp) {
        return;
      }
      if (currentBuyingPharmacyId === null) {
        return;
      }
      if (serverConflictCart) {
        return;
      }
      if (
        shoppingCartMutationSubmissions.length > 0 ||
        createdShoppingCartMutations.length > 0
      ) {
        return;
      }

      const second = pushBlobOp.second;
      setPushBlobOp(null);
      setCartUpdateExists(false);

      if (!(useBlob || pushBlobOp.force)) return;

      let conflict = false;
      if (pendingShoppingCartMutations.length > 0) {
        const itemPurchaseDetails = serverState.itemPurchaseDetails;
        const inventoryItemsInCart = serverState.inventoryItemsInCart;
        const prescriptionGroupAdjustments = serverState.prescriptionGroupAdjustments;
        const blobComponents = buildBlobPayloadComponents(itemPurchaseDetails, inventoryItemsInCart, prescriptionGroupAdjustments);

        console.log(
          `----- Pushed Change at ${new Date()} for - ${currentBuyingPharmacyId}, changes:`,
          pendingShoppingCartMutations,
          blobComponents.cartData,
          blobComponents.invData,
          blobComponents.adjData
        );
        // Push Cart to Server
        markMutationBatchStatus("submitting", pendingShoppingCartMutations);
        const accessToken = await getAccessTokenSilently();
        const pushResponse = await pushJSONBlob(
          accessToken,
          currentBuyingPharmacyId,
          blobComponents.cartData,
          blobComponents.invData,
          blobComponents.adjData,
          second
        );
        console.log(pushResponse);
        if (pushResponse.status === 200) {
          markMutationBatchStatus("submitted", pendingShoppingCartMutations);
        } else {
          if (pushResponse.data) {
            console.log("Conflict detected; marking for resolution");
            setServerConflictCart({
              cart: pushResponse.data.data.cart,
              inventory: pushResponse.data.data.inventory,
              prescriptionGroupAdjustments:
                pushResponse.data.data.prescriptionGroupAdjustments || [],
              op: pushBlobOp,
            });
            conflict = true;
          }
        }
      } else if (
        cartUpdateExists &&
        createdShoppingCartMutations.length === 0
      ) {
        const accessToken = await getAccessTokenSilently();
        const oldVersionId = getBlobVersionId(currentBuyingPharmacyId);
        const newBlobResponse = await getJSONBlob(
          currentBuyingPharmacyId,
          accessToken
        );
        if (newBlobResponse.data) {
          if (newBlobResponse.versionId !== oldVersionId) {
            setServerConflictCart({
              cart: newBlobResponse.data.cart,
              inventory: newBlobResponse.data.inventory,
              prescriptionGroupAdjustments:
                newBlobResponse.data.prescriptionGroupAdjustments || [],
              op: pushBlobOp,
            });
            conflict = true;
          }
        }
      }
      if (!conflict && pushBlobOp.force && pushBlobOp.cb) {
        pushBlobOp.cb();
      }
    })();
  }, [
    cartUpdateExists,
    setCartUpdateExists,
    serverConflictCart,
    setServerConflictCart,
    getAccessTokenSilently,
    currentBuyingPharmacyId,
    useBlob,
    pushBlobOp,
    shoppingCartMutationSubmissions,
    createdShoppingCartMutations,
    pendingShoppingCartMutations,
    serverState,
    markMutationBatchStatus,
  ]);

  useEffect(() => {
    if (serverConflictCart) {
      console.log("Resolving server conflict");
      setServerState({
        loaded: true,
        itemPurchaseDetails: serverConflictCart.cart,
        inventoryItemsInCart: serverConflictCart.inventory,
        prescriptionGroupAdjustments:
          serverConflictCart.prescriptionGroupAdjustments,
      });
      submittingShoppingCartMutations.forEach((mutationBatch) => {
        mutationBatch.mutations.forEach((mutation) => {
          console.log("Applying post-conflict mutation", mutation);
          applyMutation(mutation);
        });
      });
      markMutationBatchStatus("pending", submittingShoppingCartMutations);
      setServerState((prev) => {
        console.log("Post conflict server state", prev);
        return prev;
      });
      setServerConflictCart(() => null);
      setPushBlobOp(serverConflictCart.op);
    }
  }, [
    serverConflictCart,
    submittingShoppingCartMutations,
    applyMutation,
    setServerState,
    markMutationBatchStatus,
  ]);

  const memoizedUpdaters = useMemo((): ShoppingCartServerUpdaterContextType => {
    return {
      submitShoppingCartMutation,
      loadCartData,
      reverseUndoableMutations,
      clearMutationUndoStack,
      pushBlob,
      setUseBlob,
      submitOptimizationUpdate,
    };
  }, [
    submitShoppingCartMutation,
    loadCartData,
    reverseUndoableMutations,
    clearMutationUndoStack,
    pushBlob,
    setUseBlob,
    submitOptimizationUpdate,
  ]);

  return (
    <ShoppingCartServerStateContext.Provider
      value={{
        inventoryItemsInCart: serverState.inventoryItemsInCart,
        itemPurchaseDetails: serverState.itemPurchaseDetails,
        prescriptionGroupAdjustments: serverState.prescriptionGroupAdjustments,
        shoppingCartLoaded: serverState.loaded,
      }}
    >
      <ShoppingCartServerUpdaterContext.Provider value={memoizedUpdaters}>
        {children}
      </ShoppingCartServerUpdaterContext.Provider>
    </ShoppingCartServerStateContext.Provider>
  );
};

export const useShoppingCartServerState = () => {
  const context = useContext(ShoppingCartServerStateContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingCartServerState must be used within a ShoppingCartServerContextProvider"
    );
  }
  return context;
};

export const useShoppingCartServerUpdater = () => {
  const context = useContext(ShoppingCartServerUpdaterContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingCartServerUpdater must be used within a ShoppingCartServerContextProvider"
    );
  }
  return context;
};
