import { useMemo } from "react";
import { styled } from "twin.macro";
import { Link, LinkProps } from "react-router-dom";

export const SimpleLink = styled.a`
  text-decoration: underline;
`;

const LinkContent = SimpleLink.withComponent("span");

export function SimpleRouterLink<S>({ children, ...props }: LinkProps<S>) {
  return (
    <Link {...props}>
      <LinkContent>{children}</LinkContent>
    </Link>
  );
}

export function EmailLink({ email }: { email?: string }) {
  const href = useMemo(() => {
    return !!email && `mailto:${email}`;
  }, [email]);

  if (!href) return null;
  return <SimpleLink href={href}>{email}</SimpleLink>;
}
